import './App.css';
import { ChakraProvider,
  Box,
  NumberInput,
  NumberInputField,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Stack,
  Heading,
  Wrap,
  WrapItem,
  Button,
  Container,
  Text
} from '@chakra-ui/react'
import {useState} from 'react';
import { extendTheme } from "@chakra-ui/react"
import redbg from './images/redbg.jpg'
import { useNavigate } from 'react-router-dom';

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: 'FFFFFF'
      }
    })
  },
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
  },
  backgroundColor: '#ECEDEE',
  fonts: {
    body: "Inter",
    heading: "Inter",
    mono: "Inter",
  }
})

function App() {
  const [postcode, setPostcode] = useState(2222)
  const [sliderValue, setSliderValue] = useState(5)
  
  const labelStyles = {
    mt: '2',
    ml: '-1',
    fontSize: 'sm',
  }

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/calculate`; 
    navigate(path, {state: {postcode: postcode, sliderValue: sliderValue}});
  }

  return (
    <ChakraProvider theme={theme}>
      <link rel="preconnect" href="https://rsms.me/"></link>
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
      <Flex
        alignContent={'center'}
        justifyContent={'center'}
        bgImage={redbg}
        bgSize={'cover'}
        minH={'100vh'}
      >
        <Stack
          ml={{base: 5, md: 20}}
          mr={{base: 5, md: 20}}
          align={'center'}
          justify={{base: '', 'xl': 'center'}}
          mt={{base: 10, 'xl': 0}}
          mb={10}
        >
          <Flex
            justifyContent={'center'}
            ml={{base: 5, md: 15}}
            mr={{base: 5, md: 15}}
            mb={{base: 0, xl: 15}}
          >
            <Heading
              color={'white'}
              fontSize={['2xl', '3xl', '4xl', '5xl']}
              align={'center'}
              textShadow={'0 0 20px black'}
              maxW={1200}
            >
              HOW MUCH WILL CHRIS MINNS & LABOR'S FRESH START PLAN FOR TOLL RELIEF SAVE YOU?
            </Heading>
          </Flex>
          <Wrap
            ml={{base: 10, lg: 15, '2xl':20}}
            mr={{base: 10, lg: 15, '2xl':20}}
            p={{base: 5, md: 10}}
            pt={{base: 5, md: 15}}
            align={'center'}
            justify={'center'}
            spacing={{base: 6, '2xl': 10}}
          >
            <WrapItem
              bg={'white'}
              borderRadius={10}
              h={{base: 125, md: 150, lg: 200}}
              p={{base:5, md: 10}}
              w={['80%', 400, 400, 450, 500]}
            >
              <Stack w={'100%'}>
                <Flex justifyContent={'center'} fontWeight='bold'>
                  Enter Your Postcode
                </Flex>
                <NumberInput
                  defaultValue={postcode}
                  min={2000}
                  max={2999}
                  clampValueOnBlur={false}
                  onChange={(valueString) => setPostcode(valueString)}
                  value={(postcode)}
                  justifyContent={'center'}
                  pb={10}
                  >
                  <NumberInputField
                    bg={'white'}
                  />
                </NumberInput>
              </Stack>
            </WrapItem>
            <WrapItem
              bg={'white'}
              borderRadius={10}
              p={{base: 5, md: 10}}
              w={['80%', 400, 400, 450, 500]}
              h={{base: 180, md: 200}}
            >
              <Stack w={'100%'}>
                <Flex justifyContent={'center'} fontWeight='bold'>
                  How many times a week do you drive across the city and back?
                </Flex>
                <Box pt={6} pb={15}>
                  <Slider aria-label='slider-ex-6' defaultValue={5} min={0} max={10} step={1} onChange={(val) => setSliderValue(val)}>
                    <SliderMark value={0} {...labelStyles}>
                      0
                    </SliderMark>
                    <SliderMark value={1} {...labelStyles}>
                      1
                    </SliderMark>
                    <SliderMark value={2} {...labelStyles}>
                      2
                    </SliderMark>
                    <SliderMark value={3} {...labelStyles}>
                      3
                    </SliderMark>
                    <SliderMark value={3} {...labelStyles}>
                      3
                    </SliderMark>
                    <SliderMark value={4} {...labelStyles}>
                      4
                    </SliderMark>
                    <SliderMark value={5} {...labelStyles}>
                      5
                    </SliderMark>
                    <SliderMark value={6} {...labelStyles}>
                      6
                    </SliderMark>
                    <SliderMark value={7} {...labelStyles}>
                      7
                    </SliderMark>
                    <SliderMark value={8} {...labelStyles}>
                      8
                    </SliderMark>
                    <SliderMark value={9} {...labelStyles}>
                      9
                    </SliderMark>
                    <SliderMark value={10} {...labelStyles}>
                      10
                    </SliderMark>
                    <SliderMark
                      value={sliderValue}
                      textAlign='center'
                      bg='#B40613'
                      color='white'
                      mt='-10'
                      ml='-6'
                      w='12'
                    >
                      {sliderValue}
                    </SliderMark>
                    <SliderTrack>
                      <SliderFilledTrack bg='#B40613'/>
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Box>
              </Stack>
            </WrapItem>
          </Wrap>
          <Button
            borderRadius={10}
            fontSize={['2xl', '3xl', '4xl', '4xl']}
            p={10}
            w={['80%', 400, 400, 450, 500]}
            bg={'white'}
            color={'#B40613'}
            fontWeight={800}
            onClick={routeChange}
          >
            CALCULATE
          </Button>
        </Stack>
      </Flex>
      <Container
        as="footer"
        role="contentinfo"
        py={{
          base: '3',
          md: '8',
        }}
        justify={'center'}
        align={'center'}
      >
        <Text 
          fontSize="sm"
          color="subtle"
        >
          Authorised by Bob Nanva, Australian Labor Party (NSW Branch) Level 9, 377 Sussex Street, Sydney NSW 2000.
        </Text>
      </Container>
    </ChakraProvider>
  )
}

export default App;
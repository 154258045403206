const values = {
    2567: 33.84,
    2557: 33.84,
    2570: 33.84,
    2197: 33.84,
    2199: 33.84,
    2212: 33.84,
    2213: 33.84,
    2198: 33.84,
    2200: 33.84,
    2211: 33.84,
    2214: 33.84,
    2230: 33.84,
    2232: 33.84,
    2508: 33.84,
    2515: 33.84,
    2516: 33.84,
    2233: 33.84,
    2168: 33.84,
    2170: 33.84,
    2171: 33.84,
    2178: 33.84,
    2179: 33.84,
    2565: 33.84,
    2558: 33.84,
    2566: 33.84,
    2210: 44.26,
    2221: 44.26,
    2223: 44.26,
    2209: 44.26,
    2220: 44.26,
    2222: 44.26,
    2116: 18.82,
    2127: 18.82,
    2142: 18.82,
    2150: 18.82,
    2114: 18.82,
    2115: 18.82,
    2117: 18.82,
    2128: 18.82,
    2141: 18.82,
    2747: 31.82,
    2749: 31.82,
    2750: 31.82,
    2763: 44.66,
    2768: 44.66,
    2769: 44.66,
    2155: 44.66,
    2762: 44.66,
    2765: 44.66,
    2113: 15.54,
    2118: 15.54,
    2121: 15.54,
    2112: 15.54,
    2122: 15.54,
    2146: 33.64,
    2151: 33.64,
    2152: 33.64,
    2145: 33.64,
    2147: 33.64,
    2148: 33.64,
    2153: 33.64
}

export const getDailyCost = (value) => {
    return values[value]
}
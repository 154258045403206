import './App.css';
import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Container,
  Heading,
  Link,
  Button,
  Wrap,
} from '@chakra-ui/react'
import { extendTheme } from "@chakra-ui/react"
import bgimage from './images/bgimage.png'
import { getDailyCost } from './dailyCosts';
import { useLocation } from 'react-router-dom';

const theme = extendTheme({
    styles: {
      global: () => ({
        body: {
          bg: 'FFFFFF'
        }
      })
    },
    colors: {
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
    },
    backgroundColor: '#ECEDEE',
    fonts: {
      body: "Inter",
      heading: "Inter",
      mono: "Inter",
    }
  })  

function Savings() {
    let {state} = useLocation();

    console.log(state)

    if (state == null) {
        state = {postcode: 2000, sliderValue: 1}
    } 

    const { postcode, sliderValue } = state;
  
    return (
      <ChakraProvider theme={theme}>
        <link rel="preconnect" href="https://rsms.me/"></link>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
        <Flex
            alignContent={"center"}
            justifyContent={'center'}
            bgImage={bgimage}
            bgSize={'cover'}
            minH={'100vh'}
        >
            <Box
                w={'80%'}
                maxW={'800px'}
                mb={20}
                mt={{base: 10, md: 10}}
            >
                <Heading
                    color={'white'}
                    fontSize={['3xl', '3xl', '4xl', '5xl']}
                    align={'center'}
                    textShadow={'0 0 20px black'}
                >
                    UNDER CHRIS MINNS & LABOR'S FRESH START PLAN FOR TOLL RELIEF
                </Heading>
                <Box
                    bg={'white'}
                    px={10}
                    py={8}
                    borderRadius={10}
                    mt={10}
                    boxShadow='lg'
                >
                    {(getDailyCost(postcode) !== undefined && (getDailyCost(postcode)*sliderValue - 60) > 0) ?
                        <Box justifyContent={'center'}>
                            <Heading color={'red'} align={'center'} mb={2} fontWeight={900} fontSize={{base:'3xl', lg: '5xl'}}>
                                YOU COULD SAVE
                            </Heading>
                            <Heading color={'green'} align={'center'} fontSize={{base:'4xl', lg: '6xl'}}>
                                ${(getDailyCost(postcode)*sliderValue - 60).toFixed(2)} PER WEEK
                            </Heading>
                            <Text justifyContent={'left'} pt={6} pb={4} fontSize={18} fontWeight={'bold'}>
                                That's ${(getDailyCost(postcode)*sliderValue - 60).toFixed(2) > 0 ? ((getDailyCost(postcode)*sliderValue - 60)*52).toFixed(2):0} back in your pocket every year.
                            </Text>
                            <Text justifyContent={'left'} pb={4} fontSize={18}>
                                Over the last 12 years, Dominic Perrottet and the Liberals have privatised our motorways, making Sydney the most tolled city in the world.
                            </Text>
                            <Text justifyContent={'left'} pb={4} fontSize={18}>
                                Labor's Fresh Start Plan for Toll Relief will introduce a $60 a week toll cap that will operate in addition to the existing toll rebate and M5 cashback scheme, easing the burden of increasing tolls across Sydney.
                            </Text>
                            <Text justifyContent={'left'} pb={4} fontSize={18}>
                                We'll end the privatisation of our motorways, and overhaul our road network, untangling the toll mess and providing long-term solutions for motorists.
                            </Text>
                            <Text fontWeight={'bold'} fontSize={18} mb={6}>
                                And that's just the start.
                            </Text>
                            <Wrap 
                                align={'center'}
                                justify={'center'}
                                spacing={3}
                            >
                                <Link href="https://www.nswlabor.org.au/stop_perrottets_tollmania" target="_blank">
                                    <Button
                                        borderRadius={10}
                                        fontSize={20}
                                        py={{base: 10, md: 6}}
                                        px={6}
                                        bg={'red'}
                                        color={'white'}
                                        fontWeight='bold'
                                        justify={'center'}
                                        align={'center'}
                                        whiteSpace="normal"
                                    >
                                        Sign the petition to stop Tollmania
                                    </Button>
                                </Link>
                                <Link href="https://www.freshstartplan.com.au/" target="_blank">
                                    <Button
                                        borderRadius={10}
                                        fontSize={20}
                                        py={{base: 10, md: 6}}
                                        px={6}
                                        bg={'red'}
                                        color={'white'}
                                        fontWeight='bold'
                                        justify={'center'}
                                        align={'center'}
                                        whiteSpace="normal"
                                    >
                                        Labor's Fresh Start Plan
                                    </Button>
                                </Link>
                            </Wrap>

                        </Box>
                    :
                        <Box>
                            <Text justifyContent={'left'} pb={3} align={'center'} fontSize={'3xl'} fontWeight={'bold'}>
                                You will not pay more than
                            </Text>
                            <Heading color={'green'} align={'center'} fontSize={{base:'4xl', lg: '6xl'}} pb={3}>
                                $60 PER WEEK
                            </Heading>
                            <Text justifyContent={'left'} align={'center'} pb={3} fontSize={'3xl'} fontWeight={'bold'}>
                                regardless of your postcode and how much you drive.
                            </Text>
                            <Text justifyContent={'left'} pb={4} fontSize={18}>
                                Dominic Perrottet and the Liberals have privatised our motorways, making us the most tolled city in the world.
                            </Text>
                            <Text justifyContent={'left'} pb={4} fontSize={18}>
                                Our Fresh Start Plan for Toll Relief will introduce a $60 a week toll cap that will operate in addition to the existing toll rebate and M5 cashback scheme, easing the burden of increasing tolls across Sydney.
                            </Text>
                            <Text justifyContent={'left'} pb={4} fontSize={18}>
                                We'll end the privatisation of our motorways, and overhaul our road network, untangling the toll mess and providing long-term solutions for motorists.
                            </Text>
                            <Text fontWeight={'bold'} fontSize={18} mb={6}>
                                And that's just the start.
                            </Text>
                            <Wrap 
                                align={'center'}
                                justify={'center'}
                                spacing={3}
                            >
                                <Link href="https://www.nswlabor.org.au/stop_perrottets_tollmania" target="_blank">
                                    <Button
                                        borderRadius={15}
                                        fontSize={20}
                                        py={{base: 10, md: 6}}
                                        px={6}
                                        bg={'red'}
                                        color={'white'}
                                        fontWeight='bold'
                                        justify={'center'}
                                        align={'center'}
                                        whiteSpace="normal"
                                    >
                                        Sign the petition to stop Tollmania
                                    </Button>
                                </Link>
                                <Link href="https://www.freshstartplan.com.au/" target="_blank">
                                    <Button
                                        borderRadius={15}
                                        fontSize={20}
                                        py={{base: 10, md: 6}}
                                        px={6}
                                        bg={'red'}
                                        color={'white'}
                                        fontWeight='bold'
                                        justify={'center'}
                                        align={'center'}
                                        whiteSpace="normal"
                                    >
                                        Labor's Fresh Start Plan
                                    </Button>
                                </Link>
                            </Wrap>
                        </Box>
                    }
                </Box>
            </Box>
        </Flex>
      <Container
            as="footer"
            role="contentinfo"
            py={{
            base: '3',
            md: '8',
            }}
            justify={'center'}
            align={'center'}
        >
            <Text fontSize="sm" color="subtle">
                Authorised by Bob Nanva, Australian Labor Party (NSW Branch) Level 9, 377 Sussex Street, Sydney NSW 2000.
            </Text>
        </Container>
      </ChakraProvider>
    )
  }
  
  export default Savings;